import React, { useState, useEffect } from 'react';
import { ModalHeader, ModalBody, Spinner } from 'reactstrap';
import httpClientPy from "../../../../utils/httpClientPy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock,} from "@fortawesome/free-solid-svg-icons";
import Button from '../../../shared/Button';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Select from 'react-select'

const CheckHistory = (props) => {
    const [loading, setLoading] = useState(true);
    const [checkHistory, setCheckHistory] = useState([]);

    useEffect(() => {
        httpClientPy.get(`/comments/report/history?report_id=${props.reportId}&project_id=${props.project.id}`)
            .then((response) => {
                setCheckHistory(response.data.checks);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const customSort = (rows, selector, direction) => {
        return rows.sort((rowA, rowB) => {
         // use the selector function to resolve your field names by passing the sort comparitors
         const aField = selector(rowA)
         const bField = selector(rowB)
       
         let comparison = 0;
       
         if (aField > bField) {
          comparison = 1;
         } else if (aField < bField) {
          comparison = -1;
         }
       
         return direction === 'desc' ? comparison * -1 : comparison;
        });
    };

    const handleSort = (column, sortDirection) => {
        let data = [...checkHistory];
        const sortedData = customSort(data, column.selector, sortDirection);
        setCheckHistory(sortedData);
    };

    const columns = [
        {
            name: 'Report Name',
            selector: row => row.report_name,
            center: true,
            sortable: true,
            cell: (row) => row.report_name,
        },
        {
            name: 'Reg Name',
            selector: row => row.reg_name,
            center: true,
            sortable: true,
            cell: (row) => row.reg_name,
        },
        {
            name: 'Type',
            selector: row => row.reg_name,
            center: true,
            sortable: true,
            cell: (row) => {
                return row.section ? 'Section' : 'All';
            }
        },
        {
            name: '# of Comments',
            selector: row => row.num_of_comments,
            center: true,
            sortable: true,
            cell: (row) => row.num_of_comments,
        },
        {
          name: 'Check Date',
          id: 'check date',
          selector: row => row.created_at ? new Date(row.created_at) : new Date(0),
          width: '100px',
          center: true,
          sortable: true,
          cell: (row) => (
            <span className="badge rounded-pill darkGrey documentBadge">
              <span><FontAwesomeIcon icon={faClock} /></span>
              <span className="ms-1">
                {row.created_at
                  ? row.created_at
                  : 'Unknown'}
              </span>
            </span>
          ),
        }
    ];   

    const customStyles = {
        headCells: {
          style: {
            fontSize: '1rem',
            fontWeight: 'bold',
          },
        },
        rows: {
          style: {
            cursor: props.type === 2 ? 'default' : 'pointer',
          },
        },
        table: {
            zIndex: 0,
        },
    };

    const handleSelectClick = (row) => {
        props.updateCommentsStates(row);
        props.toggleModal();
    }

    return (
        <>
            <ModalHeader className="" toggle={()=>props.toggleModal()}>
                <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
                    {props.title}
                </h4>
            </ModalHeader>
            <ModalBody
                className="pb-0">
                {!loading && checkHistory ?
                    <>
                        {checkHistory.length > 0 ?
                            <>  
                                <div className="position-relative" style={{ zIndex: 1 }}>
                                    <DataTable
                                        className="sticky-top bg-white text-center geo-records-list-holder"
                                        pagination
                                        paginationPerPage={10}
                                        onSort={handleSort}
                                        onRowClicked={(row) => handleSelectClick(row)}
                                        searchable
                                        highlightOnHover
                                        defaultSortFieldId="check date" 
                                        defaultSortAsc={false}
                                        columns={columns}
                                        data={checkHistory}
                                        customStyles={customStyles}
                                    />
                                </div>
                            </>
                        :
                            <>
                                <div className='mb-2'>You have no history of checks, do perform a check first by closing this window, selecting a regulation and clicking on the "Check" button.</div>
                            </>
                        }
                    </>
                    :
                        <div className='w-100 py-3 text-center'>
                            <Spinner size="lg"/>
                        </div>
                }
            </ModalBody>
        </>
    )   
}

export default CheckHistory;