import { faTimes, faSpinner,faCircleChevronRight, faX, faXmark, faPlus, faMinus, faExpand, faArrowRight, faArrowLeft, faRobot, faPaperPlane, faWarning, faMagnifyingGlass, faCheck, faCopy, faDownload, faDownLeftAndUpRightToCenter, faWindowMinimize, faWindowMaximize, faThumbsUp, faThumbsDown, faBook, faTable, faList, faCity } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlay } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState, useRef } from 'react';
import {useNavigate} from "react-router-dom";
import { Card, CardBody, CardHeader, Spinner, Button, FormGroup, Input } from 'reactstrap';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import httpClientPy from "../../../../utils/httpClientPy";
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { marked } from 'marked';
import { logEvent } from '../../../shared/Mixpanel';
import PdfViewer from '../PdfViewer';

marked.setOptions({
    gfm: true,
    tables: true,
  });


const SearchAll = (props) => {

    const [lastId, setLastId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [generating, setGenerating] = useState(false);
    const [answer, setAnswer] = useState("");
    const [allRefAnswer, setAllRefAnswer] = useState("");
    const [pageNumberInput, setPageNumberInput] = useState(1);
    const [typingCivilsGPT, setTypingCivilsGPT] = useState(false);
    const [selectedReferenceType, setSelectedReferenceType] = useState(0);
    const [isUpVote, setIsUpVote] = useState(null);

    const countAll = useRef(null)
    const countCAD = useRef(null)
    const countReports = useRef(null)
    const countRegs = useRef(null)
    const [isMinimised, setisMinimised] = useState(true);

    const [chatInput, setChatInput] = useState('');
    const navigate = useNavigate();

    const handleChangeQuery = (e) => {
        setChatInput(e.target.value);
    }

    const genAiAnswer = (query) => {
        let newMessages = [];
        if (query === '') {
            return;
        }
        httpClientPy.post(`/search/init`, {
            project_id: props.project.id,
            query: query
        })
        .then((response) => {
            setLastId(response.data.search_query_id);
            newMessages.push({
                id: response.data.search_query_id,
                user_type: 'human',
                message: query,
            });
            setMessages(newMessages);
        })
        .catch((error) => {
            console.log(error);
        });
        
        setChatInput('');

    }

    const navigateToProjects = () => {
        navigate(`/projects`);
    }

    // Entry Point
    useEffect(() => {
        setLastId(props.lastId);
        
        if (props.initMessage !== '' && props.initMessage !== null) {
            let msg = {
                id: props.lastId,
                user_type: 'human',
                message: props.initMessage
            }
            setMessages([msg]);
        }

        if (props.historicalMsg !== null) {
            setMessages(props.historicalMsg);
            let vote = props.historicalMsg[0].feedback;
            if(vote !== null) {
                if(vote === true) {
                    setIsUpVote(true);
                } else if(vote === false) {
                    setIsUpVote(false);
                }
            }
        }

    }, [props.lastId]);

    const handleDownload = () => {
        if (props.fileUrl) {
            // Create an anchor element dynamically
            const link = document.createElement('a');
            link.href = props.fileUrl;
            link.target = '_blank'; // Open in a new tab or window
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };


    const fetchAIAnswer = async (chat_query, last_id) => {
        setGenerating(true);
        
        let requestBody
        let api
        const token = localStorage.getItem('auth_token');

        api = '/search/result'
        requestBody = JSON.stringify({
            project_id: props.project.id,
            search_query: chat_query,
            query_id: last_id
        });


        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL_PY + api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: requestBody,
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let text = '';
            let all_ref_text = '';
            let done_with_all_refs = false;

            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }
                
                let curr_text = decoder.decode(value);
                if (curr_text.includes('done with all refs')) {
                    done_with_all_refs = true;
                    
                } 
                
                if (done_with_all_refs && !curr_text.includes('done with all refs')) {
                    text += curr_text;
                    if(!curr_text.startsWith('{') && !curr_text.endsWith('}')) {
                        const renderedHtml = marked.parse(text);
                        setAnswer(renderedHtml);
                    }
                } else {
                    all_ref_text += curr_text;
                    all_ref_text = all_ref_text.replace('done with all refs', '');
                    setAllRefAnswer(all_ref_text);
                }
            }

            let lastId = messages[messages.length - 1].id;
            let newMessages = [...messages];
            newMessages.push({
                id: lastId + 1,
                user_type: 'ai',
                message: all_ref_text + text,
            });
            setMessages(newMessages);

        } catch (error) {
            console.error('Error:', error);
        }
        setGenerating(false);
        setAnswer("");
        logEvent('Search', { 'Query': chat_query });
    };
      
    const submitMessage = () => {
        let newMessages = [];
        if (chatInput === '') {
            return;
        }
        httpClientPy.post(`/search/init`, {
            project_id: props.project.id,
            query: chatInput
        })
        .then((response) => {
            setLastId(response.data.search_query_id);
            newMessages.push({
                id: response.data.search_query_id,
                user_type: 'human',
                message: chatInput,
            });
            setMessages(newMessages);
        })
        .catch((error) => {
            console.log(error);
        });
        
        setChatInput('');
    }

    // this is the useEffect to handle when there is new prompt
    useEffect(() => {
        if(messages && messages.length >0){
            let lastMessage = messages[messages.length - 1];
            
            if(lastMessage && lastMessage.user_type === 'human' && lastMessage.message !== '') {
                fetchAIAnswer(lastMessage.message, lastMessage.id);
            }
            if(lastMessage && lastMessage.user_type === 'ai' && lastMessage.message !== '') {

                lastMessage.message.split('\n\n').map((paragraph, index) => {
                    try {
                        let json = JSON.parse(paragraph);
                        if (json.all === false) {
                            delete json.all;
                            delete json.cad;
                            delete json.reg;

                            // get the first key of json
                            let file = Object.keys(json)[0];
                            // get the first key of the first key of json
                            let page = Object.keys(json[file])[0];
                            // get the ref ids of the first key of the first key of json
                            let ref_ids = json[file][page];

                            handleReferenceClick(file, page, ref_ids, lastMessage.id, 'any');
                        }
                    } catch (error) {
                        // do nothing
                    }
                });
                
            }
        }

    }, [messages]);

    // detect if user pressed enter
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !generating) {
            e.preventDefault();
            if(typingCivilsGPT){
                submitMessage();
            }
            else{
                props.updatePageNumber(parseInt(pageNumberInput))
            }
        }
    }

    // this is the useEffect to handle the keypress event listener
    useEffect(() => {

        // use the handleKeyPress
        window.addEventListener('keypress', handleKeyPress);

        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
            window.removeEventListener('keypress', handleKeyPress);
        };

    });

    const handleReferenceClick = (file, page, ref_ids, msg_id, type) => {
        if (props.currentFileName === file){
            props.updatePageNumber(parseInt(page));
            props.updateInitialPage(parseInt(page));
        } else {
            httpClientPy.post('/chat/reference', {
                file_name: file,
                ref_id: type === 'cad' ? ref_ids[0]: null,
                project_id: props.project.id
            }).then((response) => {
                let temp_file = file
                props.updateFileUrl(response.data.file_path);
                props.updateRecordId(response.data.record_id)
                props.updateFileName(temp_file);
                props.updateFileType(response.data.geotech);
                props.updatePageNumber(parseInt(page));
                props.updateInitialPage(parseInt(page));
            }).catch((error) => {
                console.log(error);
            })
        }

        httpClientPy.post('/chat/reference/chunk', {
            ref_ids: ref_ids,
            msg_id: msg_id
        })
        .then((response) => {
            props.updateHighlights(response.data.highlight_area);
        })
        .catch((error) => {
            console.log(error);
        });
        
        props.updateClickedMsgID(msg_id);

        if (type !== 'any') {
            props.updateOpenPreview(true)
        }
        logEvent('Reference Clicked', { 'File': file, 'Page': page, 'Type': type });  
    }


    const changeSelectedReferenceType = (id) => {
        setSelectedReferenceType(id)
        setisMinimised(false)
    }

    const handleUpvote = () => {
        httpClientPy.post('/chat/feedback', {
            query_id: messages[0].id,
            vote: true
        })
        .then((response) => {
            if (response.data.status === true) {
                setIsUpVote(true);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const handleDownvote = () => {
        httpClientPy.post('/chat/feedback', {
            query_id: messages[0].id,
            vote: false
        })
        .then((response) => {
            if (response.data.status === true) {
                setIsUpVote(false);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const renderer = (text, msg_id) => {
        try {
            const json = JSON.parse(text);
            if (!json.hasOwnProperty('all') || !json.hasOwnProperty('cad')) {
                const html = marked.parse(text);
                return <div dangerouslySetInnerHTML={{ __html: html }} />;
            } else if (json.all === false && json.cad === false && json.reg === undefined) {
                // remove key 'all' from json
                delete json.all;
                delete json.cad;
                return( 
                    <div>
                        {Object.entries(json).map(([file, pages]) => (
                        <div key={file}>
                            {file} - {' '}
                            {Object.entries(pages).map(([page_num], index, arr) => (
                                <span key={`${file}-${page_num}`}>
                                    <a
                                        className='reference-link circle-owner'
                                        style={{ backgroundColor: '#8e5fd0' }}
                                        onClick={() => handleReferenceClick(file, page_num, pages[page_num], msg_id, 'design')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {page_num}
                                    </a>
                                    {index < arr.length - 1 ? '' : ''}
                                </span>
                            ))}
                        </div>
                        ))}
                    </div>
                )
            } else if (json.all === false && json.cad === true && json.reg === undefined) {
                delete json.all;
                delete json.cad;
                return (

                    <div style={{ display: 'flex', overflowX: 'auto', gap: '10px', whiteSpace: 'wrap' }}>
                        {Object.entries(json).map(([file, pages]) => (
                            <img 
                                src={pages['image_url']}
                                alt="Image" 
                                className="card-img-top" 
                                loading='lazy'
                                ContentType="image/jpeg"
                                onClick={() => handleReferenceClick(file, 1, pages[1], msg_id, 'cad')}
                                style={{ objectFit: 'contain', width: '20%', height: '20%' }} />
                        ))}
                    </div>
                );
            } else if (json.all === false && json.cad === false && json.reg === true) {
                delete json.all;
                delete json.cad;
                delete json.reg;
                return( 
                    <div>
                        {Object.entries(json).map(([file, pages]) => (
                        <div key={file}>
                            {file} - {' '}
                            {Object.entries(pages).map(([page_num], index, arr) => (
                                <span key={`${file}-${page_num}`}>
                                    <a
                                        className='reference-link circle-owner'
                                        style={{ backgroundColor: '#8e5fd0' }}
                                        onClick={() => handleReferenceClick(file, page_num, pages[page_num], msg_id, 'reg')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {page_num}
                                    </a>
                                    {index < arr.length - 1 ? '' : ''}
                                </span>
                            ))}
                        </div>
                        ))}
                    </div>
                )
            }
        } catch (error) {
            const html = marked.parse(text);
            return <div dangerouslySetInnerHTML={{ __html: html }} />;
        }
    };    

    const all_ref_renderer = (text, msg_id) => {
        try {
            const json = JSON.parse(text);
            if (json.all === true && json.cad === true && json.reg === undefined && (selectedReferenceType === 0)) {
                delete json.all;
                delete json.cad;
                countCAD.current = Object.entries(json).length;
                return (
                    <div>
                        <div className='py-1' style={{ display: 'flex', overflowX: 'auto', gap: '10px', whiteSpace: 'wrap' }}>
                            {Object.entries(json).slice(0, 5).map(([file, pages]) => (
                                <img 
                                    src={pages['image_url']}
                                    alt="Image" 
                                    loading='lazy'
                                    className="card-img-top cad-preview"
                                    ContentType="image/jpeg"
                                    onClick={() => handleReferenceClick(file, 1, pages[1], msg_id, 'cad')}
                                    style={{ objectFit: 'contain', width: '150px',}} />
                            ))}
                        </div>
                    </div>
                );
            }
            if (json.all === true && json.cad === true && json.reg === undefined && (selectedReferenceType === 1)) {
                delete json.all;
                delete json.cad;
                return (
                    <div>
                        <div className='py-1' style={{ display: 'flex', overflowX: 'auto', gap: '10px', whiteSpace: 'wrap' }}>
                            {Object.entries(json).map(([file, pages]) => (
                                <img 
                                    src={pages['image_url']}
                                    alt="Image" 
                                    loading='lazy'
                                    className="card-img-top cad-preview"
                                    ContentType="image/jpeg"
                                    onClick={() => handleReferenceClick(file, 1, pages[1], msg_id, 'cad')}
                                    style={{ objectFit: 'contain', width: '150px',}} />
                            ))}
                        </div>
                    </div>
                );
            } else if (json.all === true && json.cad === false && json.reg === undefined && (selectedReferenceType === 0 || selectedReferenceType === 2)) {
                delete json.all;
                delete json.cad;
                countReports.current = Object.keys(json).length;

                return (
                    <>
                            <div>
                                <table class="table mt-2">
                                    <thead>
                                        <tr>
                                        <td><b>Proposal</b></td>
                                        <td><b>Page references</b></td>
                                        </tr>
                                    </thead>
                                    <tbody className='small'>
                                        {Object.entries(json).map(([file, pages]) => (
                                            <tr key={file} className='bg-white'>
                                                <td>{file}</td>
                                                <td>
                                                {Object.entries(pages).map(([page_num], index, arr) => (
                                                    <span key={`${file}-${page_num}`}>
                                                        <a
                                                            className='reference-link circle-owner'
                                                            style={{ backgroundColor: '#8e5fd0' }}
                                                            onClick={() => handleReferenceClick(file, page_num, pages[page_num], msg_id, 'design')}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {page_num}
                                                        </a>
                                                        {index < arr.length - 1 ? '' : ''}
                                                    </span>
                                                ))}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* <button className="btn btn-outline-primary btn-sm mt-2" onClick={() => handleAllReferenceHide()}>Hide all references</button> */}
                            </div>
                    </>
                );
            } else if (json.all === true && json.cad === false && json.reg === true && (selectedReferenceType === 0 || selectedReferenceType === 3)) {
                delete json.all;
                delete json.cad;
                delete json.reg;
                countRegs.current = Object.keys(json).length;
                return (
                    <>
                            <div>
                                <table class="table mt-2">
                                    <thead>
                                        <tr>
                                        <td><b>Requirements</b></td>
                                        <td><b>Page references</b></td>
                                        </tr>
                                    </thead>
                                    <tbody className='small'>
                                        {Object.entries(json).map(([file, pages]) => (
                                            <tr key={file} className='bg-white'>
                                                <td>{file}</td>
                                                <td>
                                                {Object.entries(pages).map(([page_num], index, arr) => (
                                                    <span key={`${file}-${page_num}`}>
                                                        <a
                                                            className='reference-link circle-owner'
                                                            style={{ backgroundColor: '#8e5fd0' }}
                                                            onClick={() => handleReferenceClick(file, page_num, pages[page_num], msg_id, 'reg')}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {page_num}
                                                        </a>
                                                        {index < arr.length - 1 ? '' : ''}
                                                    </span>
                                                ))}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* <button className="btn btn-outline-primary btn-sm mt-2" onClick={() => handleAllReferenceHide()}>Hide all references</button> */}
                            </div>
                    </>
                );
            }
        } catch (error) {
            // do nothing
        }
    };

    
    return (
        <>
            <div id="chatList" className="d-flex flex-column w-100">
                {/* <CardHeader className="bg-light">
                    <h5 className="title-bold">{messages[0].message}</h5>
                </CardHeader> */}
                <div id="copy_parent" 
                    className={`bg-offwhite ps-3 pe-1 ${typingCivilsGPT ? 'd-none d-sm-flex flex-column flex-grow-1' : 'd-flex flex-column flex-grow-1'}`} 
                    style={{ 
                        overflowY: 'auto',
                        minHeight: '30dvh', 
                    }}
                >
                {!generating && !answer && messages && messages.length < 2 && (
                    <div className="h-100 d-flex align-items-center px-1 px-lg-3">
                        <div key={0}>
                        <div className='text-center text-muted mb-3'>Select a prompt below to start searching this <strong>project</strong> or write your own custom prompt.</div>
                            <div className='row w-100'>
                                <div className='col-12 col-lg-4 pb-3 pb-lg-0'>
                                    <div className='card h-100 card-button' type="button" onClick={()=>genAiAnswer('Summarise the project.')}>
                                        <div class="card-body">
                                            <h5 class="card-title primary"><FontAwesomeIcon icon={faCity}></FontAwesomeIcon></h5>
                                            Create a summary of key details from this project.
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-lg-4 pb-3 pb-lg-0'>
                                    <div className='card h-100 card-button' type="button" onClick={()=>genAiAnswer('Create a table of key risks.')}>
                                        <div class="card-body">
                                            <h5 class="card-title primary"><FontAwesomeIcon icon={faTable}></FontAwesomeIcon></h5>
                                            Create a table of key construction risks.
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-lg-4 pb-3 pb-lg-0'>
                                    <div className='card h-100 card-button' type="button" onClick={()=>genAiAnswer('List a construction sequence')}>
                                        <div class="card-body">
                                            <h5 class="card-title primary"><FontAwesomeIcon icon={faList}></FontAwesomeIcon></h5>
                                            List out any constraints affecting this project.
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                    )}
                    {messages && messages.length === 2 && (
                        <>
                            <div className={props.openPreview && `d-none d-sm-block`}>
                                <div style={{ flex: 1 }}>
                                    <div id='copy_this' className="search_msg">
                                        
                                        {messages[1].message.split('\n\n').map((paragraph, index) => (
                                            <div className="message" key={index}>
                                                {renderer(paragraph, messages[0].id)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </>
                    )}
                    {generating && answer && (
                        <div style={{ flex: 1 }}>
                            <div className="search_msg">
                                <div className="message" dangerouslySetInnerHTML={{ __html: answer }} />

                            </div>
                        </div>
                    )}
                    {generating && !answer && (
                        <div className="search_msg mt-2 ">
                            <button className="btn border-0 disabled" type="button">
                                <Spinner size="sm" color="primary" /><span className='ps-2'>I'm searching your project...</span>
                            </button>
                        </div>
                    )}
                    {messages && messages.length === 2 &&
                        <div className={`d-flex justify-content-right mt-0 ${props.openPreview && 'd-none d-sm-block'}`}>
                            <button className={isUpVote && isUpVote !== null ? `btn-vote-active` : `btn-vote`} onClick={handleUpvote}><FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon></button>
                            <button className={!isUpVote && isUpVote !== null ? `btn-vote-active` : `btn-vote`} onClick={handleDownvote}><FontAwesomeIcon icon={faThumbsDown}></FontAwesomeIcon></button>
                        </div>
                    }
                </div>
                {messages && messages.length>0 &&
                    <>
                        <div className='border-top bg-secondary border-bottom py-3 bg-light mt-2 d-none d-sm-flex align-items-center'>
                            <span className="title-bold px-3 mb-0 pb-0 me-3"><b>Relevant docs</b></span>
                            <div className="d-flex flex-grow-1">
                                <a className={`btn btn-sm overview_button border-secondary me-2 mb-0 ${selectedReferenceType === 0 && 'active'}`} onClick={() => changeSelectedReferenceType(0)}>
                                    All {(countReports.current || countCAD.current) && <span>({countReports.current + countCAD.current + countRegs.current})</span>}
                                </a>
                                <a className={`btn btn-sm overview_button border-secondary mb-0 ${selectedReferenceType === 2 && 'active'}`} onClick={() => changeSelectedReferenceType(2)}>
                                    Proposals {countReports.current && <span>({countReports.current})</span>}
                                </a>
                                <a className={`btn btn-sm overview_button border-secondary ms-2 mb-0 ${selectedReferenceType === 3 && 'active'}`} onClick={() => changeSelectedReferenceType(3)}>
                                    Regs & specs {countRegs.current && <span>({countRegs.current})</span>}
                                </a>
                                <a className={`btn btn-sm overview_button border-secondary ms-2 mb-0 ${selectedReferenceType === 1 && 'active'}`} onClick={() => changeSelectedReferenceType(1)}>
                                    CAD {countCAD.current && <span>({countCAD.current})</span>}
                                </a>
                            </div>
                            <a className='btn btn-link text-dark ms-auto me-2' onClick={() => setisMinimised(!isMinimised)}>
                                <FontAwesomeIcon icon={faWindowMaximize}></FontAwesomeIcon>
                            </a>
                        </div>

                        <div className={`border-top bg-secondary border-bottom py-3 bg-light mt-2 align-items-center justify-content-between ${typingCivilsGPT ? 'd-none' : 'd-flex d-sm-none'}`}>
                            <div className="d-flex align-items-center">
                                <span className="title-bold px-3 mb-0 pb-0"><b>Answer</b></span>
                                <FormGroup switch>
                                    <Input
                                        type="switch"
                                        checked={props.openPreview}
                                        onClick={() => {
                                            props.updateOpenPreview(!props.openPreview);
                                        }}
                                        disabled={generating} 
                                        />
                                </FormGroup>
                                <span className="title-bold px-3 mb-0 pb-0"><b>Reference</b></span>
                            </div>
                            <div className="d-flex align-items-center pe-3">
                                {props.openPreview &&
                                    <a className={`btn btn-sm overview_button border-secondary mb-0 ${!props.fileUrl && 'disabled'}`} onClick={() => handleDownload()}>
                                        <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                                    </a>
                                }
                            </div>
                        </div>


                        <div 
                            className="px-3 bg-white flex-grow-1 d-flex flex-column position-relative" 
                            style={{ 
                                flex: 1, 
                                overflowY: 'scroll', 
                                minHeight: isMinimised ? '0dvh' : '30dvh', 
                                maxHeight: isMinimised ? '0dvh' : '30dvh',
                                transition: 'min-height 0.3s, max-height 0.3s' // Optional: for smooth transition
                            }}
                            >
                            {generating && allRefAnswer ? (
                                <div className="flex-grow-1 pt-2">
                                    <div>
                                        {allRefAnswer.split('\n\n').map((paragraph, index) => (
                                            <div className="" key={index}>
                                                {all_ref_renderer(paragraph, messages[0].id)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                )
                            :
                            messages && messages.length === 2 && (
                                <div className="flex-grow-1 pt-2">
                                    <div>
                                        {messages[1].message.split('\n\n').map((paragraph, index) => (
                                            <div className="" key={index}>
                                                {all_ref_renderer(paragraph, messages[0].id)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                }
            </div>


            <div className='container bg-light px-0 mx-auto px-3 py-2'>
                <div className="d-flex align-items-center">
                    <div className="form-floating text-dark flex-grow-1 me-2">
                        <input 
                            id='question_chat' 
                            autoComplete="off" 
                            onFocus={()=>setTypingCivilsGPT(true)}
                            onBlur={()=>setTypingCivilsGPT(false)}
                            onChange={(e) => handleChangeQuery(e)} 
                            type="text" 
                            value={chatInput}
                            className="form-control gpt_input" 
                            placeholder={"Search for info in all your project docs"} 
                        />
                        <label htmlFor='question_chat' className='text-secondary'>
                            Search for info in all your project docs
                        </label>
                    </div>
                    {(generating || chatInput.length<1) ? (
                        <button className="btn btn-secondary disabled border-0 btn-lg" disabled type="button">
                            <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                        </button>  
                    ) : (
                        <button className="btn btn-primary border-0 btn-lg" type="button" onClick={() => submitMessage()}>
                            <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                        </button>    
                    )}
                </div>

            </div>
        </>
    )

}

export default SearchAll;
