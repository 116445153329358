import Loader from "../../shared/Loader";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import moment, { max } from "moment";
import { useEffect, useState, useRef } from "react";
import GeoRecordDetailsButton from "../../geoRecords/geoRecordDetailsButton";
import ProgressCounter from "./ProgressCounter";
import Button from "../../shared/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Modal, ModalBody, Card, CardBody, Alert, Table, Input, FormGroup, Tooltip, Spinner } from "reactstrap";
import { faFileExcel, faLocationDot, faMapPin, faFilePdf, faFileCode, faEye, faMapLocation, 
        faDatabase, faList, faClock, faPenToSquare, faLock, faLockOpen, faLocationCrosshairs, faTriangleExclamation, faCloudArrowUp, faTrash, faSquarePollHorizontal, faTag, faBook, faMap, faMagnifyingGlass, faPieChart, faMagnifyingGlassChart, 
        faDownload,
        faImage,
        faEdit,
        faWarning,
        faRefresh} from "@fortawesome/free-solid-svg-icons";
import DataTable from 'react-data-table-component';
import httpClientPy from "../../../utils/httpClientPy";
import ImageCard from "./ImageCard";
import EditCadForm from "./EditCadForm";

library.add(faFileExcel, faMapPin, faLocationDot, faFilePdf, faMapLocation, faFileCode, faEye, faDatabase,faTriangleExclamation, faList, faClock, faCloudArrowUp);

const BoreHoleLogsList = (props) => {
  //geoRecords is used to store and filter the georecords
  const [filteredgeoRecords, setFilteredGeoRecords] = useState();
  
  // geoRecordId is used to store the id of the georecord that is being updated
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [cadDownloadLoading, setCadDownloadLoading] = useState(false);
  const [isEditCad, setIsEditCad] = useState(false);
  const [cadEditInfo, setCadEditInfo] = useState({'id': null, 'pdf_url': null});
  const link = useRef();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  //use effect which selected is changed
  useEffect(() => {
    if (props.type === 1){
      props.handleCheckboxChange(selected)
    }
  }, [selected])


  const handleCheckboxChange = (item) => {
    if (selected.includes(item)) {
      setSelected(selected.filter((selectedItem) => selectedItem !== item));
    } else {
      setSelected([...selected, item]);
    }
  };

  // Example row click handler function
  const handleRowClick = (row) => {
    if (row.geotech === 2 || row.status !== 'ready') {
      return;
    }
    else if (row.geotech===1 && row.status.toLowerCase() === 'ready'){
        handleCheckboxChange(row.id)
        if(row.position && row.position.lat && row.position.lng){
          props.recordClicked(
            {
              lat: row.position.lat,
              lng: row.position.lng,
            }
          );
        }
        else{
          props.recordClicked(0);
        }
    }
    else{
      props.recordClicked(row);
    }
    
  };


  const triggerDelete = (id, type) => {
    httpClientPy.delete(`/geo/record/delete?geo_record_id=${id}&record_type=${type}&record_id=0`)
    .then((response) => {
      if (response.data.status === true) {
        props.boreHoleInfoUpdate(id);
      }
    }).catch((err) => {
      console.log(err);
    });
  };



  // for geotech
  useEffect(() => {
    if(props.downloadSelection === 'visible'){
      if(filteredgeoRecords){
        let start = 0
        if (currentPage > 1) {
          start = (currentPage-1)*rowsPerPage-1;
        }
        // index end
        let end = (currentPage)*rowsPerPage;
        
        // clone filteredgeoRecords
        let clone = [...filteredgeoRecords];
        const readyIds = clone.filter(row => row.status.toLowerCase() === 'ready').map(row => row.id).slice(start, end);
        setSelected(readyIds);
      }
    }
    else if(props.downloadSelection === 'all'){
      if (filteredgeoRecords) {
        const readyIds = filteredgeoRecords.filter(row => row.status.toLowerCase() === 'ready').map(row => row.id);
        setSelected(readyIds);
      }
    } 
    else{
      setSelected([]);
    }
  }, [props.downloadSelection, filteredgeoRecords, rowsPerPage]);

  // for geotech
  const downloadCadPDF = (id) => {
    setCadDownloadLoading(true);
    link.current = document.createElement("a");
    httpClientPy.get(`/geo/record/fetch/pdf?record_id=${id}&project_id=${props.project.id}`, {
      responseType: 'blob'  // Important: Set response type as 'blob'
    }).then(response => {
      const file = new Blob(
        [response.data], 
        { type: 'application/pdf' }  // Set the MIME type to 'application/pdf'
      );
      let url = URL.createObjectURL(file);
      link.current.href = url;
      link.current.download = url.split("/").pop();
      link.current.click();
      setCadDownloadLoading(false);
    }).catch(error => {
      console.error('Error fetching PDF:', error);
    });
  }

  const editCad = (id, file_url) => {
    setCadEditInfo({'id': id, 'pdf_url': file_url});
    setIsEditCad(true);
  }

  const refreshCad = (id) => {
    setCadDownloadLoading(true);
    httpClientPy.post(`/geo/record/doc/rectify`,
    {
      record_ids: [id],
      to_class: 2,
    })
    .then((response) => {
      if (response.data.status === 'True') {
        props.boreHoleInfoUpdate(true);
        setCadDownloadLoading(false);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  const columns = [
    {
      name: '',
      width: '35px',
      cell: (row) => (
        <>
        {row.geotech===1 &&
        <FormGroup check inline>
          <Input
            type="checkbox"
            onChange={() => handleCheckboxChange(row.id)}
            checked={selected.includes(row.id)}
            disabled={row.status.toLowerCase() !== 'ready'}
          />
        </FormGroup>
        }
        </>
      ),
    },
    {
      name: 'File name',
      selector: row => {
        if (row.geotech===1) {
          if (row.borehole_name) {
            return row.borehole_name;
          } else if (row.description) {
            return row.description;
          } else if (row.reference) {
            return row.reference;
          } else {
            return 'Unknown project';
          }
        } else {
          return row.original_file_name;
        }
      },
      minWidth: '100px',
      maxWidth: props.type === 2 ? '450px' : '100%',
      center: true,
      sortable: true,
      cell: (row) => {
        if(row.geotech===1){
          if (row.borehole_name) {
            return (
                row.borehole_name
            );
          } else if (row.description) {
            return row.description;
          } else if (row.reference) {
            return row.reference
          } else {
            return <small>Unknown project</small>;
          }
        } else {
          return row.original_file_name;
        }
      },
    },
    {
      name: 'Project title',
      selector: row => row.geotech===1 ? row.description : props.project.name,
      minWidth: '100px',
      maxWidth: '200px',
      center: true,
      sortable: true,
    },
    {
      name: 'Added',
      id: 'uploaded',
      selector: row => row.created_datetime ? new Date(row.created_datetime) : new Date(0), // Return raw datetime for sorting
      width: '110px',
      center: true,
      sortable: true,
      cell: (row) => (
        <span className="badge rounded-pill darkGrey documentBadge">
          <span><FontAwesomeIcon icon={faClock} /></span>
          <span className="ms-1">
            {row.created_datetime
              ? moment(row.created_datetime).format('DD/MM/YYYY')
              : 'Unknown'}
          </span>
        </span>
      ),
    },
    {
      name: 'Status',
      width: '110px',
      selector: row => row.status,
      center: true,
      sortable: true,
      cell: (row) => <ProgressCounter status={row.status} requestedDate={row.created_datetime} />,
    },
    {
      name: '',
      width: '50px',
      center: true,
      cell: (row) => {
        return (
          <>
            {row.is_private && row.status !== 'pending' && row.status !== 'post-pending' && (
              row.geotech===1 ? (
                <span>
                  <DeleteConfirmation itemId={row.id} type='geotech' onDeleteConfirm={triggerDelete} />
                </span>
                ) : (
                  <>
                  {row.geotech === 2 ? (
                    <div className="d-flex justify-content-center">
                      {cadDownloadLoading ? (
                        <>
                          <Spinner size="sm" color="primary" />
                          </>
                      ) : (
                          <DeleteConfirmation itemId={row.id} type='all' onDeleteConfirm={triggerDelete} disabled={cadDownloadLoading} />
                      )}
                    </div>
                  ) : (
                    <span>
                      <DeleteConfirmation itemId={row.id} type='all' onDeleteConfirm={triggerDelete} />
                    </span>
                  )}
                  </>
                )
            )}
          </>
        );
      },
    }
  ];   


  // Conditionally add the Details column only if props.type is not 1
  if (props.type === 0) {
    columns.splice(5, 0, {
      name: 'Check',
      width: '130px',
      center: true,
      cell: (row) =>
        row.status.toLowerCase() === 'ready' && (
          <GeoRecordDetailsButton
            onClick={() => props.recordCheck(row)}
            label={<FontAwesomeIcon className="icon-view" icon={faMagnifyingGlassChart} />}
            className="btn"
            geoRecord={row}
            />
          )
    });
    // columns.splice(6, 0, {
    //   name: 'Comments',
    //   width: '120px',
    //   center: true,
    //   cell: (row) =>
    //     0
    // });
  }

  // Conditionally add the Details column only if props.type is not 1
if (props.type === 1) {
  columns.splice(5, 0, {
    name: 'Details',
    width: '90px',
    center: true,
    cell: (row) =>
      row.status.toLowerCase() === 'ready' && (
        <GeoRecordDetailsButton
          onClick={() => props.onViewDetails(row)}
          label={<FontAwesomeIcon className="icon-view" icon={faMagnifyingGlassChart} />}
          className="btn"
          geoRecord={row}
          />
        )
  });
}
else if(props.type===2){

  columns.splice(5, 0, {
    name: 'Data',
    width: '70px',
    cell: (row) => (
      <>
      {row.status === 'ready' ? (
        <>
          {row.drawing_title ? (
            <Button
                label={<FontAwesomeIcon icon={faMagnifyingGlassChart}/>}
                className="btn"
                onClick={() => editCad(row.id, row.pdf_url)}
                disabled={cadDownloadLoading}
            />
          ) : (
            <>
            <Button
                label={<FontAwesomeIcon icon={faRefresh}/>}
                id="refreshcad"
                className="btn"
                onClick={() => refreshCad(row.id)}
                disabled={cadDownloadLoading}
            />
            <Tooltip
              isOpen={tooltipOpen}
              target="refreshcad"
              toggle={toggle}
            >
              We updated our CAD extraction, click here to reprocess this CAD.
            </Tooltip>
            </>
          )}
          </>
      ) : (
        null
      )}
      </>
    )
  })

  columns.splice(6, 0, {
      name: 'Download',
      width: '120px',
      cell: (row) => (
        <>
          <Button
              label={<FontAwesomeIcon icon={faDownload}/>}
              className="btn"
              onClick={() => downloadCadPDF(row.id, row.pdf_url)}
              disabled={cadDownloadLoading}
          />
        </>
      )
  })
  
  columns.splice(0, 0, {
      name: '',
      width: '220px',
      cell: (row) => (
        <>
        {/* Drawing preview goes here */}
        {row.image_url &&
          <ImageCard
            image_url={row.image_url}
            />
          }
        </>
      )
    })

  columns.splice(3, 0, {
    name: 'Drawing Title',
    width: '220px',
    cell: (row) => (
      <>
        {row.drawing_title ? (
          <span>{row.drawing_title}</span>
        ) : (
          <span>{row.original_file_name}</span>
        )}
      </>
    )
  })

}
  
  const customStyles = {
    headCells: {
      style: {
        fontSize: '1rem',
        fontWeight: 'bold',
        zIndex: 0,
      },
    },
    rows: {
      style: {
        cursor: props.type === 2 ? 'default' : 'pointer',
      },
    },
  };


  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
     // use the selector function to resolve your field names by passing the sort comparitors
     const aField = selector(rowA)
     const bField = selector(rowB)
   
     let comparison = 0;
   
     if (aField > bField) {
      comparison = 1;
     } else if (aField < bField) {
      comparison = -1;
     }
   
     return direction === 'desc' ? comparison * -1 : comparison;
    });
   };

  const handleSort = (column, sortDirection) => {
      // clone filteredgeoRecords
      let data = [...filteredgeoRecords];
      const sortedData = customSort(data, column.selector, sortDirection);
      setFilteredGeoRecords(sortedData);
    };


  useEffect(() => {
    if (props.data) {
      setFilteredGeoRecords(props.data);
    }
  }
  , [props.data]);


  return (
    <>
      <div>
        {props.loading &&
          <div className="mt-5">
            <Loader loading={props.loading} />
          </div>
        }
        {filteredgeoRecords && filteredgeoRecords.length > 0 && (
          <>
          <DataTable
            className="sticky-top bg-white text-center geo-records-list-holder"
            pagination
            paginationRowsPerPageOptions={props.type === 2? [5, 10, 20] : [5, 10, 15, 25, 50, 100]}
            paginationPerPage={props.type === 2 ? 5 : 10}
            onSort={handleSort}
            searchable
            highlightOnHover
            defaultSortFieldId="uploaded" 
            defaultSortAsc={false}
            columns={columns}
            data={filteredgeoRecords}
            onRowClicked={handleRowClick} // Add this line to handle row click
            customStyles={customStyles}
            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
              setRowsPerPage(currentRowsPerPage);
              setCurrentPage(currentPage);
            }}
            onChangePage={(currentPage) => {
              setCurrentPage(currentPage);
            }}
          />
          </>
        )}

        {(!filteredgeoRecords || filteredgeoRecords.length === 0) && !props.loading && (
          <>
            <h6 className="text-muted text-center pt-3">
                No data is in your project database yet, try extracting geotech data from a document.
            </h6>
          </>
        )}
      </div>
      <Modal id="editCad" centered={true} fullscreen={true} isOpen={isEditCad} toggle={()=>setIsEditCad(!isEditCad)}>
        <ModalBody className='h-100 p-0'>
        <EditCadForm 
          toggleModal={()=>setIsEditCad(!isEditCad)}
          fileUrl={cadEditInfo.pdf_url}
          id={cadEditInfo.id}
        />
        </ModalBody>
      </Modal>
    </>
  );
};

export default BoreHoleLogsList;
