import React, { useState, useEffect, useRef} from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';

import { Spinner, Tooltip } from 'reactstrap';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCity, faCropSimple, faMagnifyingGlassArrowRight, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import html2canvas from 'html2canvas';
import httpClientPy from '../../../utils/httpClientPy';


const PdfViewer = (props) => {
    
    const [fileUrl, setFileUrl] = useState(false);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);
    const [tooltipOpen3, setTooltipOpen3] = useState(false);
    const [tooltipOpen4, setTooltipOpen4] = useState(false);
    const [isSnipping, setIsSnipping] = useState(false);
    const [snipArea, setSnipArea] = useState(null);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const pdfContainerRef = useRef(null);

    const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
    const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
    const toggle3 = () => setTooltipOpen3(!tooltipOpen3);
    const toggle4 = () => setTooltipOpen4(!tooltipOpen4);

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const zoomPluginInstance = zoomPlugin();

    const { jumpToPage } = pageNavigationPluginInstance;


    useEffect(() => {
        if(props.pageNumberInput) {
            jumpToPage(props.pageNumberInput - 1);
            if (!isSnipping || !snipArea) return;
            pdfContainerRef.current.removeChild(snipArea);
            setSnipArea(null);
            setStartX(0);
            setStartY(0);
        }
    }, [props.pageNumberInput]);

    const renderHighlights = (properties) => {
        if (!props.highlightArea || props.highlightArea.length === 0) {
            return null;
        }
    
        return (
            <div>
                {props.highlightArea
                    .filter((area) => area.pageIndex === properties.pageIndex)
                    .map((area, idx) => (
                        <div
                            key={idx}
                            className="highlight-area"
                            style={Object.assign(
                                {},
                                {
                                    background: 'purple',
                                    opacity: 0.2,
                                },
                                properties.getCssProperties(area, properties.rotation)
                            )}
                        />
                    ))}
            </div>
        );
    };

    const highlightPluginInstance = highlightPlugin({
        renderHighlights,
        trigger: Trigger.TextSelection,
    });

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

    const toggleSnipping= () => {
        if (!isSnipping){
            props.updateTask(3)
        }
        setIsSnipping(!isSnipping)
    }


    const CustomToolbar = () => (
        <Toolbar>
            {(slots) => {
                const {
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    CurrentPageInput,
                    ZoomOut,
                    ZoomIn,
                    Download,
                    // ... other slots you want to keep
                } = slots;
    
                return (
                    <>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ padding: '0px 2px' }}>
                                <GoToPreviousPage />
                            </div>
                            <div style={{ padding: '4px 2px', display: 'flex', alignItems: 'center' }}>
                                <CurrentPageInput /> / <NumberOfPages />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <GoToNextPage />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomIn />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomOut />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <Download />
                            </div>
                            {props.fileType === 0 &&
                                <div style={{ padding: '0px 2px' }}>
                                        <button aria-label="Crop" className={isSnipping ? 'crop-button-selected' : 'crop-button'} id="cropButton" onClick={() => toggleSnipping()}>
                                            <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" >
                                                <g clipPath="url(#clip0_15_434)" >
                                                    <rect width="24" height="24" />
                                                    <path d="M5 1V5M5 5H1M5 5V18C5 18.5523 5.44772 19 6 19H16" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M19 23L19 19M19 19L23 19M19 19L19 6C19 5.44772 18.5523 5 18 5L8 5" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs >
                                                    <clipPath id="clip0_15_434" >
                                                        <rect width="24" height="24" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            &nbsp; 
                                            <small className='text-muted'>Check
                                            {/* {isSnipping &&
                                            <span>&nbsp; <FontAwesomeIcon icon={faCircleXmark} width={'1rem'} /></span>
                                            } */}
                                            </small>
                                        </button>

                                        {/* <Tooltip isOpen={tooltipOpen4} placement={'bottom'} target="cropButton" toggle={toggle4}>
                                            Crop
                                        </Tooltip> */}
                                </div>
                            }
                        </div>
                    </div>
                    </>
                );
            }}
        </Toolbar>
    );

    const handleSnipStart = (e) => {
        if (!isSnipping) return;
        // clear any existing snip area
        if (snipArea) {
            pdfContainerRef.current.removeChild(snipArea);
            setSnipArea(null);
        }
        const { clientX, clientY } = e.nativeEvent;
        const rect = pdfContainerRef.current.getBoundingClientRect();
        const offsetX = clientX - rect.left;
        const offsetY = clientY - rect.top;
        setStartX(offsetX);
        setStartY(offsetY);
    
        const area = document.createElement('div');
        area.className = 'snip-area';
        area.style.left = `${offsetX}px`;
        area.style.top = `${offsetY}px`;
        setSnipArea(area);
        pdfContainerRef.current.appendChild(area);
    };
    
    const handleSnipping = (e) => {
        if (!isSnipping || !snipArea) return;
        const { clientX, clientY } = e.nativeEvent;
        const rect = pdfContainerRef.current.getBoundingClientRect();
        const offsetX = clientX - rect.left;
        const offsetY = clientY - rect.top;
        snipArea.style.width = `${Math.abs(offsetX - startX)}px`;
        snipArea.style.height = `${Math.abs(offsetY - startY)}px`;
        snipArea.style.left = `${Math.min(startX, offsetX)}px`;
        snipArea.style.top = `${Math.min(startY, offsetY)}px`;
    };
    
    const handleSnipEnd = () => {
        if (!isSnipping || !snipArea) return;
        setIsSnipping(false);
    
        snipArea.style.backgroundColor = 'transparent';
    
        html2canvas(pdfContainerRef.current, {
            x: parseInt(snipArea.style.left, 10),
            y: parseInt(snipArea.style.top, 10),
            width: parseInt(snipArea.style.width, 10),
            height: parseInt(snipArea.style.height, 10),
            useCORS: true,
            backgroundColor: null,
            scale: 2,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            props.updateBase64img(imgData);
            // Restore the background color
            snipArea.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
            // Remove snip area after capturing
            pdfContainerRef.current.removeChild(snipArea);
            setSnipArea(null);
            setStartX(0);
            setStartY(0);
            setIsSnipping(false);
        });

    };


    const style = {
        cursor: isSnipping ? 'crosshair' : 'default', // Change cursor based on the state
      };

    return (
        <>
            <div>
                <CustomToolbar />
            </div>
            <div id="pdf_viewer" 
                className="h-100 mt-2 position-relative"
                onMouseDown={handleSnipStart}
                onMouseMove={handleSnipping}
                onMouseUp={handleSnipEnd}
                ref={pdfContainerRef}
                style={style}
            >
                <div className="row h-100 align-content-center">
                    {props.fileUrl &&
                        <Viewer 
                            fileUrl={props.fileUrl} 
                            initialPage={props.initialPage - 1}
                            enableSmoothScroll={true}
                            plugins={[
                                pageNavigationPluginInstance, 
                                highlightPluginInstance,
                                toolbarPluginInstance,
                            ]} 
                        />
                    }
                </div>
                <div id="search_tools" className={`${props.task !== 0 ? 'd-none d-sm-block' : 'd-none'}`} style={{ zIndex: 9999 }}>
                    <div>
                        <div id="tooltip1" className={`btn btn-panel border mb-3 ${props.task === 1 && 'active'}`} onClick={()=>props.updateTask(1)}>
                            <FontAwesomeIcon icon={faCity} width={'1rem'} />
                        </div>
                            <Tooltip isOpen={tooltipOpen1} placement={'left'} target="tooltip1" toggle={toggle1}>
                                Search all project
                            </Tooltip>
                        </div>
                    <div>
                        <div id="tooltip2" className={`${props.fileType === 2 ? 'btn btn-panel mb-3 border grey' : `btn btn-panel mb-3 border ${props.task === 2 && 'active'}`}`} onClick={props.fileType === 2 ? null : ()=>props.updateTask(2)}>
                            <FontAwesomeIcon icon={faMagnifyingGlassArrowRight} />
                        </div>
                        <Tooltip isOpen={tooltipOpen2} placement={'left'} target="tooltip2" toggle={toggle2}>
                            {props.fileType === 2 ? 'Currently this feature is only available for proposals': 'Search open document'}
                        </Tooltip>
                    </div>
                    <div>
                        <div id="tooltip3" className={`${props.fileType !== 0 ? 'btn btn-panel mb-3 border grey' : `btn btn-panel mb-3 border ${props.task === 3 && 'active'}`}`} onClick={props.fileType !== 0 ? null : ()=>props.updateTask(3)}>
                            <FontAwesomeIcon icon={faTriangleExclamation} />
                        </div>
                        <Tooltip isOpen={tooltipOpen3} placement={'left'} target="tooltip3" toggle={toggle3}>
                            {props.fileType !== 0 ? 'Currently this feature is only available for proposals': 'Comments & Issues'}
                        </Tooltip>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PdfViewer;
