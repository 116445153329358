import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import httpClientPy from "../../utils/httpClientPy";
import {Modal} from 'reactstrap';
import SideBar from "./SideBar";
import ProjectOverview from "./detailComponents/ProjectOverview";
import SearchResult from "./detailComponents/SearchResult";
import UploadsList from "./AiAssitantComponents/UploadsList";

const loadGoogleMapsScript = () =>
  new Promise((resolve, reject) => {
    if (document.getElementById("google-maps-script")) return resolve();
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places,geometry`;
    script.onload = () => resolve();
    script.async = true;
    script.id = "google-maps-script";
    document.querySelector("body")?.appendChild(script);
});

const ProjectDetail = (props) => {


  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [projectExtraDetails, setProjectExtraDetails] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const [polygonCenter, setPolygonCenter] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(16);
  const [displayMap, setDisplayMap] = useState(false);

  const [userInfo, setUserInfo] = useState(null);

  const [chatEnabled, setChatEnabled] = useState(window.innerWidth < 500 ? true : false);
  const [chatMessage, setChatMessage] = useState(null);
  const [historicalMsg, setHistoricalMsg] = useState(null)
  const [lastId, setLastId] = useState(0);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // set pageview from state provided type if available
  const [pageView, setPageView] = useState( null);
  // set subpage from state provided subpage if available
  const [subpage, setSubpage] = useState( null );

  const getProjectData = (id) => {
    httpClientPy.get(`/project/${id}`)
      .then((response) => {
        setProject(response.data.project);
        setProjectExtraDetails(response.data.extra_data);
        let polygon = response.data.project.polygon;
        polygon.push(polygon[0]);
        let polygon_object = getPolygonCoordinates(polygon);
        let distance = 6*haversineDistance(polygon_object.minLat, polygon_object.minLng, polygon_object.maxLat, polygon_object.maxLng,)
        let zoom = calculateZoomLevel(distance);
        setZoomLevel(zoom);
        setPolygonCenter([polygon_object.lat, polygon_object.lng]);
        setPolygon(polygon);
        
        if (response.data.project.project_type !== 2) {
          setDisplayMap(true);
        }
        else{
          setDisplayMap(false);
        }

        setLoading(false);
      })
      .catch((err) => {
        // console.log(err.response.data.detail)
        setError(true);
        setErrorMessage(err.response.data.detail);
        setLoading(false);
      }
    );
  }

  useEffect(() => {
    loadGoogleMapsScript();


    // const handleResize = () => {
    //   setChatEnabled(window.innerWidth < 500);
    // };

    // window.addEventListener('resize', handleResize);

    // return () => {
    //   document.getElementById("google-maps-script")?.remove();
    //   window.removeEventListener('resize', handleResize);
    // };

  }, []);

  useEffect(() => {

    if (location.state === null) {
      setError(true);
      setErrorMessage("Project not found");
      setLoading(false);
    } else {
      // set pageview from state provided type if available
      setPageView( location.state !== null ? location.state.type : null);
      // set subpage from state provided subpage if available
      setSubpage( location.state !== null ? location.state.subpage : null);

      if (location.state.project === undefined) {
        const id = location.state.id
        getProjectData(id);
      } else {
        const id = location.state.project.id;
        getProjectData(id);
      }
    }
  }, []);

  const handleChat = async (text) => {
    try {
        if(text !== null && text !== ''){
          let response = await httpClientPy.post(`/search/init`, { project_id: project.id, query: text });
          let lastId = response.data.search_query_id;

          setLastId(lastId);
          setChatMessage(text);
          setChatEnabled(true);
        }
    } catch (err) {
        console.log(err);
        setChatMessage(null);
        setChatEnabled(false);
    }
  }

  const handleSearchHistoryClick = (id) => {
      httpClientPy.post(`/search/single/history`, {
        project_id: project.id,
        query_id: id,
    })
    .then((response) => {
      setLastId(response.data.messages[1].id)
      setHistoricalMsg(response.data.messages);
      setChatEnabled(true);
    })
    .catch((error) => {
        console.log(error);
    });
    
  }

  const handleChatClose = () => {
    setChatEnabled(false);
    setChatMessage(null);
    setHistoricalMsg(null);
  }
  
  return (
      <>
          <SideBar page={'detail'} 
                    loading={loading}
                    subpage={subpage} type={pageView} setType={(type)=>setPageView(type)}  setSubpage={(type)=>setSubpage(type)}
                    project={project} 
                    chatEnabled={chatEnabled}
                    closeChat={()=>handleChatClose()}
                    setUserInfo={(user)=>setUserInfo(user)} 
                    projectExtraDetails={projectExtraDetails}
                    userInfo={userInfo}
                    />
          {!loading ? (
            <>
            {!error ? (
              <>
              {pageView === null ||  pageView === undefined ? 
                <div className="row">
                  { !chatEnabled ? (
                    <div className="container-flex">
                      <ProjectOverview 
                        project={project}
                        displayMap={displayMap}
                        extraDetails={projectExtraDetails}
                        handleChat={(text)=>handleChat(text)}
                        handleSearchHistoryClick={(id)=>handleSearchHistoryClick(id)}
                        getProjectData={(id)=>getProjectData(id)}
                        userInfo={userInfo} 
                        toggleMap = {()=>setDisplayMap(!displayMap)}
                        polygon={polygon}
                        polygonCenter={polygonCenter}
                        zoomLevel={zoomLevel}
                      />
                    </div>
                  ) : (
                    <div className="container-flex">
                      <SearchResult 
                        project={project} 
                        initMessage={chatMessage} 
                        historicalMsg={historicalMsg}
                        lastId={lastId}
                        handleChatClose={()=>handleChatClose()}
                        userInfo={userInfo}
                        task={1}
                      />
                    </div>
                  )}
                </div>
                :
                <UploadsList project={project} pageView={pageView}/>
                }
              </>
            ) : (
              <>
                  <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                      <img src="assets/build_img.png" height={'100px'} alt="Civils.ai" />
                      <h2><b>Oops!</b></h2>
                      <h4>{errorMessage}</h4>
                      <button className="btn btn-primary mt-2" onClick={() => navigate('/projects')}>View all projects</button>
                  </div>
              </>
            )}
            </>
          ) : (
            <>
                <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                    <span>
                        <span
                            className="spinner-border spinner-border-md"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Loading...</span>
                    </span>
                </div>
            </>
          )}
      </>
  )

}

export default ProjectDetail;


function getPolygonCoordinates(polygonCoordinates) {
  if (polygonCoordinates.length === 0) {
    return null;
  }

  let centroidX = 0, centroidY = 0;
  let det = 0, tempDet = 0;
  let j = 0;
  let nVertices = polygonCoordinates.length;

  // Initialize bounding box values
  let maxLat = -90, minLat = 90;
  let maxLng = -180, minLng = 180;

  for (let i = 0; i < nVertices; i++) {
    // Update bounding box
    maxLat = Math.max(maxLat, polygonCoordinates[i][0]);
    minLat = Math.min(minLat, polygonCoordinates[i][0]);
    maxLng = Math.max(maxLng, polygonCoordinates[i][1]);
    minLng = Math.min(minLng, polygonCoordinates[i][1]);

    // The last vertex connects to the first
    j = (i + 1) % nVertices;

    // Calculate the determinant
    tempDet = polygonCoordinates[i][0] * polygonCoordinates[j][1] - polygonCoordinates[j][0] * polygonCoordinates[i][1];
    det += tempDet;

    centroidX += (polygonCoordinates[i][0] + polygonCoordinates[j][0]) * tempDet;
    centroidY += (polygonCoordinates[i][1] + polygonCoordinates[j][1]) * tempDet;
  }

  // The total determinant is divided by 2 because the formula for the area of the polygon is 1/2 the determinant
  det = det / 2;

  // Centroid is the sum of x (or y) coordinates times the determinant, divided by 6 times the area
  centroidX = centroidX / (6 * det);
  centroidY = centroidY / (6 * det);

  // Handling negative area for clockwise polygons
  // if (det < 0) {
  //   centroidX = -centroidX;
  //   centroidY = -centroidY;
  //   det = -det;
  // }

  return {
    'lng': centroidY, 
    'lat': centroidX,
    'maxLat': maxLat,
    'minLat': minLat,
    'maxLng': maxLng,
    'minLng': minLng,
  };
}


function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
}

// Calculate appropriate zoom level based on distance
function calculateZoomLevel(distance) {
  let zoomLevel = Math.floor(16 - Math.log2(distance)); // Adjust the base value (16) as needed
  if(zoomLevel>18){
    zoomLevel = 18
  }
  return zoomLevel;
}
